import { Image, Container, Title, Text, Group, Stack, Flex, Card, Box } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import classes from './Landing.module.css';
import TriggerModal from '../auth/TriggerModal';
import AuthPage from '../auth/AuthPage';
import Autoplay from 'embla-carousel-autoplay';
import { useCoursesQuery } from '../../queries/courses';
import { useRef } from 'react';

export function Landing() {
  const {isLoading, data} = useCoursesQuery({})
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <Container>
     <Group justify='space-between' className={classes.inner}>
        <Stack>
          <Title size={'h3'}>
            Learn a <span className={classes.highlight}>course</span> in a way <br />that is both enjoyable and effective, <br />all at no cost.
          </Title>
          <Group mt={30}>
            <TriggerModal triggerProps={{radius:"xl", size:"md", fullwidth: true, className: classes.control}} label="Get Started">
              <AuthPage name="signup" />
            </TriggerModal>
            <TriggerModal triggerProps={{variant:"default", radius:"xl", size:"md",className: classes.control}} label="I already have an account">
              <AuthPage name="login" />
            </TriggerModal>
          </Group>
        </Stack>
        <Image src={"/assets/images/il1.png"} h={400}/>
     </Group>
     {!isLoading && <Box>
      <Carousel
        withIndicators
        slideSize="20%"
        slideGap="xs"
        loop
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.play}
        align="start"
        slidesToScroll={5}
      >
      {data?.map((course) => (
        <Carousel.Slide  key={course.uid}>
          <Card shadow="sm">
            <Flex gap='sm' align='center'>
              <Image src={course.image || course.school.logo} h={32}/>
              <Title size={'h3'} >{course.code}</Title>
            </Flex>
            <Text truncate w="150">{course.name}</Text>
          </Card>
        </Carousel.Slide>
      ))}
      </Carousel>
     </Box>}
    </Container>
  );
}