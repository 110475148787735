import { useMutation } from '@tanstack/react-query'
import axios from './AxiosInstance'
import { handleError } from './utils'
import useUserStore from '../store/user'

export const useRegisterMutation = (props) => {
    const userStore = useUserStore()

  return useMutation(
    {
    mutationFn: (payload) => axios.post(`/coursex/register/`, payload),
      onSuccess: (response) => {
        userStore.setUser(response.data)
        if (props?.onSuccess) {
          props.onSuccess(response.data)
        }
        window.location.href = '/'
      },
      onError: (error) => {
        if (props?.onError) {
          props.onError(error)
        } else {
            handleError(error)
        }
      }
    },
  )
}
