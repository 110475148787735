import { Image, Container, Title, Text, Group, Stack } from '@mantine/core';
import classes from './Landing.module.css';
import TriggerModal from '../auth/TriggerModal';
import AuthPage from '../auth/AuthPage';

export function About() {
  return (
    <Container>
     <Group justify='space-between' className={classes.inner}>
       <Image src={"/assets/images/interative-learning.png"} h={400}/>

        <Stack>
          <Title size={'h3'}>
          Interactive Learning Experience
          </Title>
          <Text maw={400}>Say goodbye to monotonous lectures and static textbooks. We transforms your learning into a fun and interactive experience. Dive into a world of quizzes that challenge, engage, and reinforce your understanding of key concepts.</Text>
        </Stack>
     </Group>
     <Group justify='space-between' className={classes.inner}>
       <Image src={"/assets/images/personalized.png"} h={400}/>

        <Stack>
          <Title size={'h3'}>
            Personalized Learning Paths
          </Title>
          <Text maw={400}>No two learners are the same. Our app tailors quizzes to your unique learning style, ensuring that you get the most out of every question. Say hello to a personalized learning journey designed just for you.</Text>
        </Stack>
     </Group>
     <Group justify='space-between' >

        <Stack>
          <Title size={'h3'}>
            Real-Time Feedback
          </Title>
          <Text maw={400}>Receive instant feedback on your quiz performance. Know what you got right, understand your mistakes, and track your progress over time. CourseXQuiz empowers you to learn from every attempt, making your study sessions more effective.</Text>
        </Stack>
       <Image src={"/assets/images/feedback.png"} h={400}/>

     </Group>
      <Stack align='center' className={classes.inner}>
        <Title size={'h3'}>
          Ready to Elevate Your Learning?
        </Title>
        <Text maw={600}>Don't settle for passive learning. Embrace the power of active engagement with CourseXQuiz. Join thousands of learners who are already experiencing a transformative approach to education.</Text>
        <Group mt={30}>
            <TriggerModal triggerProps={{radius:"xl", size:"md",className: classes.control}} label="Get Started">
              <AuthPage name="signup" />
            </TriggerModal>
            <TriggerModal triggerProps={{variant:"default", radius:"xl", size:"md",className: classes.control}} label="I already have an account">
              <AuthPage name="login" />
            </TriggerModal>
          </Group>
      </Stack>
    </Container>
  );
}