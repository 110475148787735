import { Container, Divider, Loader, Modal, Progress } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks';
import React, { useCallback, useEffect } from 'react'
import WarningModal from '../common/WarningModal';
import Question from './Question';
import EndScreen from './EndScreen';
import { useQuestionsQuery } from '../../queries/questions';
import { useStatsMutation } from '../../queries/stats';

let timer = null;

export default function QuizModal({ section, opened, close}) {
    const [openedWarning, { open: openWarning, close: closeWarning}] = useDisclosure(false);
    const [currentQuestion, setCurrentQuestion] = React.useState(0)
    const [totalCorrectAnswers, setTotalCorrectAnswers] = React.useState(0)
    const [finished, setFinished] = React.useState(true)
    const [responses, setResponses] = React.useState([])
    const {isLoading, data} = useQuestionsQuery(section?.uid)
    const mutation = useStatsMutation(section?.uid)

    const getNextQuestion = () => {
        clearTimeout(timer)
        if (currentQuestion === data.length - 1) {
            setFinished(true)
            mutation.mutate({
                responses,
                score: Math.floor((totalCorrectAnswers / data.length) * 100),
            })
             return;
         }
         setCurrentQuestion(currentQuestion + 1)
    }

    const handleOptionSelected = (option) => {
        setResponses([...responses, {
            question: data[currentQuestion].uid,
            correct: option.isCorrect,
        }])
        if (option.isCorrect) {
            setTotalCorrectAnswers(totalCorrectAnswers + 1)
        }
        timer = setTimeout(() => {
            getNextQuestion()
        }, 5000)
    }

    const getCurrentProgress = () => {
        return Math.ceil((currentQuestion / data?.length) * 100)
    }

    const handleTryAgain = useCallback(() => {
        setCurrentQuestion(0)
        setTotalCorrectAnswers(0)
        setFinished(false)
    }, [])

    useEffect(() => {
        if (section?.uid) {
            handleTryAgain()
        }
    }, [section?.uid, handleTryAgain])

    if (!data) return null
  return (
    <Modal
        opened={opened}
        onClose={() => {
            if (finished) {
                close();
                return;
            }
            openWarning();
        }}
        title={section?.title || "Quiz"}
        fullScreen={true}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <WarningModal 
            opened={openedWarning}
            onCancel={() => {
                closeWarning();
            }}
            onConfirm={() => {
                closeWarning();
                close();
            }}
            message="Are you sure you want to exit the quiz?"
        />
        <Divider mb="sm"/>
        <Container>
            {finished ? <EndScreen totalCorrectAnswers={totalCorrectAnswers} totalQuestions={data.length} onTryAgain={handleTryAgain}/> : (<>
                <Progress value={getCurrentProgress()} striped animated size="xl" />
                {isLoading && <Loader />}
                <Question question={data[currentQuestion]} onOptionSelected={handleOptionSelected} onContinue={getNextQuestion} />
            </>)}
        </Container>
      </Modal>
  )
}
