import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'
import useUserStore from '../store/user'
import { useEffect } from 'react'

export const useUserQuery = () => {
  const {setUser} = useUserStore()

  const {data, ...rest}  = useQuery(
    {
      queryKey: ['userme'],
      queryFn: () => axios.get(`/coursex/me/`),
      select: (response) => response.data,
    },
  )

  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data, setUser])

  return {
    data,
    ...rest
  }
}
