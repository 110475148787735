import { Box } from '@mantine/core'
import React from 'react'
import { Landing } from './Landing'
import { About } from './About'
import Courses from '../courses'
import useUserStore from '../../store/user'

export default function Home() {
  const {isLoggedIn} = useUserStore()
  if (isLoggedIn()) return <Courses />
  return (
    <Box>
        <Landing />
        <About />
    </Box>
  )
}
