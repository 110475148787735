import { Center, Flex, Loader, ScrollArea, SimpleGrid, Text, Tooltip } from '@mantine/core'
import React from 'react'
import cx from 'clsx';
import QuizModal from '../quiz';
import { useDisclosure } from '@mantine/hooks';
import { useChapterSectionsQuery } from '../../queries/sections';
import classes from './styles.module.css'

export default function Steps({chapterUid}) {
    const [selectedSection, setSelectedSection] = React.useState(null);
    const [opened, {toggle, close}] = useDisclosure(false);
    const {isLoading, data} = useChapterSectionsQuery(chapterUid)
    if (isLoading) return <Loader />
  return (
    <>
    <QuizModal opened={opened} close={close} section={selectedSection} />
    <ScrollArea.Autosize>
    <SimpleGrid cols={{base: 1, sm: 2, md: 3, lg: 4}}>
          {data.map((section, i) => (
            <Flex
              key={section.uid}
              gap={'xs'}
              align={'center'}
            >
              <Tooltip label={`${section.score}%`}>
              <Center className={cx(classes.circle, {
                [classes.circleCompleted]: section.score > 50,
                [classes.circleInprogress]: section.score > 0 && section.score <= 50,
              })}
              onClick={() => {
                setSelectedSection(section)
                toggle()
              }}
              >
                <Text>{i + 1}</Text>
              </Center>
              </Tooltip>
              <Text>{section.title}</Text>
            </Flex>
          ))}
      </SimpleGrid>
      </ScrollArea.Autosize>
    </>

  )
}