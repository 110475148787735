import React, { useEffect } from 'react'
import { Image, Container, Title, Box, Card, Progress, Text, SimpleGrid, Stack, Loader } from '@mantine/core';
import FilterCourses from '../common/FilterCourses';
import { useCoursesQuery } from '../../queries/courses';

export default function Courses() {
    const [filter, setFilter] = React.useState({})
    const {isLoading, data, refetch} = useCoursesQuery({params: filter})
    
    useEffect(() => {
        refetch()
    }, [filter, refetch])

    return (
    <Container>
        <FilterCourses filter={filter} setFilter={setFilter} />
        {isLoading && <Loader />}
        {data?.length === 0 && !isLoading && <Text align={'center'}>No courses found</Text>}
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4 }}>
            {data?.map((course) => (
                <Card w="100%" key={course.uid} shadow="sm" component='a' href={`/courses/${course.uid}`}>
                    <Stack gap={'xs'}>
                        <Box>
                            <Image src={course.image || course.school.logo} fallbackSrc=''/>
                            <Title size={'h3'}>{course.code}</Title>
                            <Text>{course.name}</Text>
                        </Box>
                        <Progress value={course.progress} />
                    </Stack>
                </Card>
            ))}
        </SimpleGrid>
    </Container>
  )
}
