import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'

export const useChapterSectionsQuery = (chapterUid) => {
  return useQuery(
    {
      queryKey: ['sections', chapterUid],
      queryFn: () => axios.get(`/coursex/${chapterUid}/sections/`),
      select: (response) => response.data,
    },
  )
}
