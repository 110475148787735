import React from 'react'
import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Button,
  } from '@mantine/core';
  import { useForm } from '@mantine/form'
  import classes from './LoginForm.module.css';
import { useLoginMutation } from '../../queries/login';

export default function LoginForm(props) {
  const mutation = useLoginMutation()
  const form = useForm({
    initialValues: { email: '', password: '' },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  return (
    <Container size={420} my={40}>
    <Title ta="center" className={classes.title}>
      Welcome back!
    </Title>
    <Text c="dimmed" size="sm" ta="center" mt={5}>
      Do not have an account yet?{' '}
      <Anchor size="sm" component="button" onClick={() => props.setPageName && props.setPageName("signup")}>
        Create account
      </Anchor>
    </Text>

    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <form onSubmit={form.onSubmit((values) => {
        mutation.mutate(values)
      })}>
        <TextInput label="Email" placeholder="you@domain.com" required {...form.getInputProps('email')}/>
        <PasswordInput label="Password" placeholder="Your password" required mt="md" {...form.getInputProps('password')}/>
        {/* <Group justify="space-between" mt="lg">
          <Anchor component="button" size="sm" onClick={() => props.setPageName && props.setPageName("forgot-password")}>
            Forgot password?
          </Anchor>
        </Group> */}
        <Button fullWidth mt="xl" type="submit">
          Sign in
        </Button>
      </form>
    </Paper>
  </Container>
  )
}
