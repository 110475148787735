import { create } from 'zustand'

const useUserStore = create((set, get) => ({
    user: null,
    setUser: (user) => {
        localStorage.setItem('x-token', user.uid)
        set({ user })
    },
    logout: () => {
        localStorage.removeItem('x-token')
        set({ user: null })
    },
    updateUser: (user) => set({ user: { ...get().user, ...user } }),
    isLoggedIn: () => localStorage.getItem('x-token') !== null,
}))

export default useUserStore