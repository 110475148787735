import React from 'react'
import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Button,
  } from '@mantine/core';
  import { useForm } from '@mantine/form'
  import classes from './LoginForm.module.css';
import { useRegisterMutation } from '../../queries/register';

export default function SignupForm(props) {
  const mutation = useRegisterMutation()
  const form = useForm({
    initialValues: { email: '', password: '' },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length < 8 ? 'Password must be at least 8 characters long' : null)
    },
  })


  return (
    <Container size={420} my={40}>
    <Title ta="center" className={classes.title}>
      Register
    </Title>
    
    <Text c="dimmed" size="sm" ta="center" mt={5}>
      Already have an account?{' '}
      <Anchor size="sm" component="button" onClick={() => props.setPageName && props.setPageName("login")}>
        Login
      </Anchor>
    </Text>

    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
    <form onSubmit={form.onSubmit((values) => {
        mutation.mutate(values)
      })}>
      <TextInput label="Email" placeholder="you@mantine.dev" required {...form.getInputProps('email')}/>
      <PasswordInput label="Password" placeholder="Your password" required mt="md" {...form.getInputProps('password')}/>
      <Button fullWidth mt="xl" type='submit'>
        Sign up
      </Button>
      </form>
    </Paper>
  </Container>
  )
}
