import { useHeadroom, useDisclosure } from '@mantine/hooks';
import { Outlet, useNavigate } from "react-router-dom";
import { AppShell, Text, Divider, Group, rem, ScrollArea, Stack, UnstyledButton } from '@mantine/core';
import Logo from './Logo';
import classes from './MainLayout.module.css';
import Header from './Header';
import { IconLogout, IconX } from '@tabler/icons-react';
import { useUserQuery } from '../../queries/user';
import useUserStore from '../../store/user';
import FullPageLoader from './FullPageLoader';
import ThemeSwitch from './ThemeSwitch';
import { SUPER_ADMIN_EMAILS } from '../constants';

export function MainLayout(props) {
  const pinned = useHeadroom({ fixedAt: 720 });
  const [opened, { toggle }] = useDisclosure();
  const {isLoading} = useUserQuery()
  const userStore = useUserStore()
  const navigate = useNavigate()

  if (isLoading) return <FullPageLoader />
  return (
    <AppShell 
        header={{ height: 60, offset: false }}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: !opened, mobile: !opened } }}
        padding="md"
    >
      <AppShell.Header>
        <Header pinned={pinned} opened={opened} toggle={toggle} />
      </AppShell.Header>
      <AppShell.Navbar py="md" px={4}>
        <Group justify="space-between" px="xl">
          <Logo />
          <IconX onClick={toggle}/>
        </Group>
        <Divider variant="solid" my="md" />
        <AppShell.Section grow my="md" component={ScrollArea}>
          <UnstyledButton className={classes.control} onClick={() => {
            toggle()
            navigate('/')
          }}>Courses</UnstyledButton>
          {userStore.isLoggedIn() && SUPER_ADMIN_EMAILS.includes(userStore.user?.decryptedEmail)  && <UnstyledButton className={classes.control} onClick={() => {
            toggle()
            navigate('/loadcourse')
          }}>Load Course</UnstyledButton>}
          {/* <UnstyledButton className={classes.control}>Request a course</UnstyledButton> */}
        </AppShell.Section>
        {userStore.isLoggedIn() && <AppShell.Section>
          <Stack pl='md'>
            <ThemeSwitch />
            <Text c="dimmed" size="sm" ml={'md'}>{userStore.user?.decryptedEmail}</Text>
            <UnstyledButton className={classes.link} onClick={() => {
              userStore.logout()
              toggle()
            }}>
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </UnstyledButton>
          </Stack>
        </AppShell.Section>}
      </AppShell.Navbar>
      <AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}