import { Flex, Loader } from '@mantine/core'
import React from 'react'

export default function FullPageLoader() {
  return (
    <Flex justify={'center'} align={'center'} h="100vh">
        <Loader />
    </Flex>
  )
}
