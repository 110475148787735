import { Button, Card,  Flex,  Stack, Title } from '@mantine/core'
import React, { useEffect } from 'react'
import styles from "./styles.module.css"
import { IconCheck, IconX } from '@tabler/icons-react'
import cx from 'clsx';
import { useMediaQuery } from '@mantine/hooks';

export default function Question({question, onOptionSelected, onContinue}) {
    const isMobile = useMediaQuery('(max-width: 50em)');
    const [selectedOption, setSelectedOption] = React.useState(null)

    useEffect(() => {
        setSelectedOption(null)
    }, [question])

  return (
    <>
    <Title fw={400} size="h2" ta="center" my={isMobile ? "xl" : "100"}>{question?.title}</Title>
    <Stack gap="md" mt="xl">
        {question?.options.map((option) => {
            const failed = !selectedOption?.isCorrect && selectedOption === option
            const success = selectedOption?.isCorrect && selectedOption === option
            const showCorrect = selectedOption && option.isCorrect
            return (
            <Card  shadow="sm" radius="xl" p="xl" className={cx(styles.option, [
                {
                    [styles.fail]: failed,
                    [styles.success]: success || showCorrect,
                }
            ])} onClick={() => {
                if (!selectedOption) {
                    setSelectedOption(option)
                    onOptionSelected && onOptionSelected(option)
                }
            }}>
                <Flex gap={'xl'} align={'center'} justify={'space-between'}>
                    <Title size="h3" ta="center" w="100%">{option.title}</Title>
                    {success && <IconCheck size={24} />}
                    {failed && <IconX size={24} />}
                </Flex>
            </Card>
        )})}
        {selectedOption && <Flex justify={'flex-end'}>
            <Button onClick={onContinue}>Continue</Button>
        </Flex>}
    </Stack>
    </>
  )
}
