import {
    Routes,
    Route,
    useLocation,
  } from "react-router-dom";

  import React from 'react'
import { MainLayout } from "./components/common/MainLayout";
import Home from "./components/home";
import AuthPage from "./components/auth/AuthPage";
import CourseDetail from "./components/courses/CourseDetail";
import LoadCourse from "./components/loadcourse";
  
  export default function Router() {
    const location = useLocation();
    return (
        <Routes location={location}>
            <Route element={<AuthPage showLogo name="login"/>} path={'/auth/:token?'}></Route>
            <Route
                element={
                    <MainLayout />
                }
            >
                <Route element={<Home />} path={'/'}></Route>
                <Route element={<LoadCourse />} path={'/loadcourse'}></Route>
                <Route element={<CourseDetail />} path={'/courses/:slug'}></Route>
            </Route>
        </Routes>
    )
  }
  