import React from 'react'
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import SignupForm from './SignupForm';
import Logo from '../common/Logo';
import { Center } from '@mantine/core';
import ResetPasswordForm from './ResetPasswordForm';
import { useParams } from 'react-router-dom';

export default function AuthPage(props) {
    const [pageName, setPageName] = React.useState(props.name);
    const {token} = useParams();

    const getForm = () => {
        if (!!token ) return <ResetPasswordForm />
        if (pageName === 'login' ) return <LoginForm setPageName={setPageName} />
        if (pageName === 'forgot-password' ) return <ForgotPasswordForm setPageName={setPageName}/>
        if (pageName === 'signup' ) return <SignupForm setPageName={setPageName} />
        return <SignupForm />
    }
    return (
        <>
            {props.showLogo && <Center my="xl">
             <Logo />
            </Center>}
            {getForm()}
        </>
    )
}
