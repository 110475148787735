import { notifications } from '@mantine/notifications';


export const getApiErrors = (error) => {
    const fieldErrors = error?.response?.data?.fieldErrors;
    if (fieldErrors) {
        return Object.values(fieldErrors).flat().join(", ");
    } else {
        const errors = error?.response?.data?.errors;
        if (errors) {
            return errors[0].reason;
        }
    }
   
    return error?.message;
};

export const handleError = (error) => {
    const errorMessage = getApiErrors(error);
    showError(errorMessage)
};

export const showError = (message) => {
    notifications.show({
        title: 'Error',
        color: 'red',
        message: message || "Something went wrong.",
    })
}

export const showSuccess = (message) => {
    notifications.show({
        title: 'Info',
        color: 'green',
        message: message || "Successfully processed.",
    })
}