import React from 'react'
import { Modal, Button, Text, Flex } from '@mantine/core';

export default function WarningModal(props) {
  return (
      <Modal size="sm"  opened={props.opened} onClose={props.onCancel} title={props.title}>
          <Text>{props.message}</Text>
          <Flex justify="flex-end" mx="lg" gap={'xl'} mt="xl">
            <Button onClick={props.onCancel} variant="default" >{props.cancelLabel || 'Cancel'}</Button>
            <Button onClick={props.onConfirm}>{props.confirmLabel || "Yes"}</Button>
          </Flex>
      </Modal>
  )
}
