import { Box, Switch, useMantineColorScheme } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';
import React from 'react'

export default function ThemeSwitch() {
    const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <Box>
        <Switch
            size='lg'
            label="Toggle theme"
            onLabel={<IconSun />}
            offLabel={<IconMoon />}
            checked={colorScheme === 'light'}
            onChange={() => setColorScheme(colorScheme === 'light' ? 'dark' : 'light')}
        />
    </Box>
  )
}
