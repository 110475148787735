import React from 'react'
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

export default function TriggerModal(props) {
    const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
        <Modal size="xl"  opened={opened} onClose={close} title={props.title}>
            {props.children}
        </Modal>
        <Button onClick={open} {...props.triggerProps}>{props.label}</Button>
    </>
  )
}
