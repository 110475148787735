import React from 'react'
import {
    TextInput,
    Group,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Button,
    Center,
    Box,
    rem
  } from '@mantine/core';
  import classes from './LoginForm.module.css';
  import { IconArrowLeft } from '@tabler/icons-react';

export default function ForgotPasswordForm(props) {
  return (
    <Container size={420} my={40}>
    <Title ta="center" size="h3" className={classes.title}>
      Forgot your password?
    </Title>
    <Text c="dimmed" size="sm" ta="center" mt={5}>
      Enter your email to get a reset link
    </Text>

    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <TextInput label="Your email" placeholder="you@coursexquiz.com" required />
      <Group justify="space-between" mt="lg" className={classes.controls}>
          <Anchor c="dimmed" size="sm" className={classes.control}  onClick={() => props.setPageName && props.setPageName("login")}>
            <Center inline>
              <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
              <Box ml={5}>Back to the login page</Box>
            </Center>
          </Anchor>
          <Button className={classes.control}>Reset password</Button>
        </Group>
    </Paper>
  </Container>
  )
}
