import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'

export const useChaptersQuery = (courseUid) => {
  return useQuery(
    {
      queryKey: ['chapters', courseUid],
      queryFn: () => axios.get(`/coursex/${courseUid}/chapters/`),
      select: (response) => response.data,
    },
  )
}
