import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'

export const useSchoolsQuery = () => {
  return useQuery(
    {
      queryKey: ['schools'],
      queryFn: () => axios.get(`/coursex/schools/`),
      select: (response) => response.data,
    },
  )
}
