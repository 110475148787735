import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from './AxiosInstance'
import { handleError } from './utils'

export const useStatsMutation = (sectionUid, props) => {
  const queryClient = useQueryClient()

  return useMutation(
    {
    mutationFn: (payload) => axios.post(`/coursex/${sectionUid}/stats/`, payload),
      onSuccess: (response) => {
        queryClient.invalidateQueries(['sections'])
        if (props?.onSuccess) {
          props.onSuccess(response.data)
        }
      },
      onError: (error) => {
        if (props?.onError) {
          props.onError(error)
        } else {
            handleError(error)
        }
      }
    },
  )
}
