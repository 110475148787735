import { Box, Select, Switch, TextInput, Flex, Divider } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { useSchoolsQuery } from '../../queries/schools'
import React from 'react'

export default function FilterCourses(props) {
    const {data: schools} = useSchoolsQuery()
  return (
    <Box px="md" mb="xl">
        <Flex gap={'md'} align={'center'} justify={'center'} wrap={'wrap'}>
            <TextInput
                rightSectionPointerEvents="none"
                rightSection={<IconSearch />}
                placeholder="Search courses"
                w={{base: '100%', md: 'auto'}}
                onChange={(e) => props.setFilter({...props.filter, search: e.currentTarget.value})}
            />
            
            <Select
                w={{base: '100%', md: 'auto'}}
                placeholder="Select school"
                data={schools?.map(school => ({value: school.uid, label: school.name}))}
                onChange={(value) => props.setFilter({...props.filter, school: value})}
                clearable
                searchable
            />

            <Switch
                onChange={(e) => props.setFilter({...props.filter, onlyInProgress: e.currentTarget.checked})}
                label="Only courses in progress"
            />
        </Flex>
        <Divider my="xl"/>
    </Box>
  )
}
