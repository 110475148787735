import React from 'react'
import { Container, Title, Box, Card, Stack,  TextInput, Button, Flex, JsonInput, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import useUserStore from '../../store/user';
import { SUPER_ADMIN_EMAILS } from '../constants';
import { useLoadCourseMutation } from '../../queries/loadcourse';

export default function LoadCourse() {
    const userStore = useUserStore()
    
    const fm = useForm({
        initialValues: {
            schoolShortName: '',
            schoolName: '',
            courseCode: '',
            courseName: '',
            chapterName: '',
            chapterTitle: '',
            chapterPosition: 0,
            sectionTitle: '',
            sectionPosition: 0,
            questions: '',
            isLast: false,
        },
    })
    const mutation = useLoadCourseMutation({onSuccess: () => {
        fm.setFieldValue('sectionPosition', Number(fm.values.sectionPosition) + 1)
        fm.setFieldValue('sectionTitle', '')
        fm.setFieldValue('questions', '')
        if(fm.values.isLast) {
            fm.setFieldValue('chapterPosition', Number(fm.values.chapterPosition) + 1)
            fm.setFieldValue('sectionPosition', 0)
            fm.setFieldValue('chapterTitle', '')
            fm.setFieldValue('isLast', false)
        }
    }})
    const handleSubmit = (values) => {
        const payload = {
            school: {
                shortName: values.schoolShortName,
                name: values.schoolName,
            },
            course: {
                code: values.courseCode,
                name: values.courseName,
            },
            chapter: {
                name: values.chapterName,
                title: values.chapterTitle,
                position: values.chapterPosition,
            },
            chapterSection: {
                title: values.sectionTitle,
                position: values.sectionPosition,
            },
            questions: JSON.parse(values.questions),
        }
        mutation.mutate({data: payload})
    }
    if (!SUPER_ADMIN_EMAILS.includes(userStore.user?.decryptedEmail)) return null

    return (
    <Container>
        <form onSubmit={fm.onSubmit(handleSubmit)}>
        <Stack gap={'xl'}>
            <Box>
                <Title size={'h3'}>Load Course</Title>
            </Box>
            <Card shadow='md'>
                <Title size={'h3'}>School</Title>
                <Flex gap={'xs'} >
                    <TextInput label="Short name" {...fm.getInputProps("schoolShortName")} style={{flex: 1}}  required/>
                    <TextInput label="Name" {...fm.getInputProps("schoolName")} style={{flex: 1}} required />
                </Flex>
            </Card>
            <Card shadow='md'>
                <Title size={'h3'}>Course</Title>
                <Flex gap={'xs'} >
                    <TextInput label="Code" {...fm.getInputProps("courseCode")} style={{flex: 1}} required/>
                    <TextInput label="Name" {...fm.getInputProps("courseName")} style={{flex: 1}}  required/>
                </Flex>
            </Card>
            <Card shadow='md'>
                <Title size={'h3'}>Chapter</Title>
                <Flex gap={'xs'} >
                    <TextInput label="Name" {...fm.getInputProps("chapterName")} style={{flex: 1}} required/>
                    <TextInput label="Title" {...fm.getInputProps("chapterTitle")} style={{flex: 1}}  required/>
                    <TextInput label="Position" type='number' {...fm.getInputProps("chapterPosition")} style={{flex: 1}}  required/>
                </Flex>
            </Card>
            <Card shadow='md'>
                <Title size={'h3'}>Section</Title>
                <Flex gap={'xs'} align={'flex-end'}>
                    <TextInput label="Title" {...fm.getInputProps("sectionTitle")} style={{flex: 1}} required/>
                    <TextInput label="Position" type='number' {...fm.getInputProps("sectionPosition")} style={{flex: 1}}  required/>
                    <Checkbox label="Is Last" {...fm.getInputProps("isLast", {type: "checkbox"})} style={{flex: 1}}  />
                </Flex>
            </Card>
            <Card shadow='md'>
                <Title size={'h3'}>Questions</Title>
                <JsonInput {...fm.getInputProps("questions")} rows={10} placeholder='valid json content' required/>
            </Card>
            <Button type="submit" loading={mutation.isPending}>Load</Button>
        </Stack>
        </form>
    </Container>
  )
}
