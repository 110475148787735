import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'

export const useCoursesQuery = ({params}) => {
  return useQuery(
    {
      queryKey: ['courses', params],
      queryFn: () => axios.get(`/coursex/courses/`, {params}),
      select: (response) => response.data,
    },
  )
}
