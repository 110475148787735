import { useMutation } from '@tanstack/react-query'
import axios from './AxiosInstance'
import { handleError, showSuccess } from './utils'

export const useLoadCourseMutation = (props) => {
    

  return useMutation(
    {
    mutationFn: (payload) => axios.post(`/coursex/load_questions/`, payload),
      onSuccess: (response) => {
        if (props?.onSuccess) {
          props.onSuccess(response.data)
        }
        showSuccess('Course loaded successfully')
      },
      onError: (error) => {
        if (props?.onError) {
          props.onError(error)
        } else {
            handleError(error)
        }
      }
    },
  )
}
