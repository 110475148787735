import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from './AxiosInstance'
import { handleError } from './utils'
import useUserStore from '../store/user'

export const useLoginMutation = (props) => {
    const userStore = useUserStore()
    const queryClient = useQueryClient()

  return useMutation(
    {
    mutationFn: (payload) => axios.post(`/coursex/login/`, payload),
      onSuccess: (response) => {
        userStore.setUser(response.data)
        queryClient.invalidateQueries(['userme'])
        queryClient.invalidateQueries(['courses'])
        if (props?.onSuccess) {
          props.onSuccess(response.data)
        }
        window.location.href = '/'
      },
      onError: (error) => {
        if (props?.onError) {
          props.onError(error)
        } else {
            handleError(error)
        }
      }
    },
  )
}
