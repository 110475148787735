import { Group, Burger } from '@mantine/core'
import React from 'react'
import Logo from './Logo'
import TriggerModal from '../auth/TriggerModal'
import AuthPage from '../auth/AuthPage'
import useUserStore from '../../store/user'

export default function Header({pinned, opened, toggle}) {
  const userStore = useUserStore()
  return (
    <Group h="100%" px="md">
        {userStore.isLoggedIn() && <Burger opened={opened} onClick={toggle}  size="sm" />}
        <Group justify="space-between" style={{ flex: 1 }}>
            <Logo />
            {!pinned && !userStore.isLoggedIn() && <Group ml="xl" gap={0} visibleFrom="sm">
                <TriggerModal triggerProps={{radius:"xl", size:"md"}} label="Get Started">
                    <AuthPage name="signup" />
                </TriggerModal>
            </Group>}
        </Group>
    </Group>
  )
}
