import { Box, Stack, Image, Title, Badge, Button } from '@mantine/core'
import React from 'react'

export default function EndScreen({totalCorrectAnswers, totalQuestions, onTryAgain}) {
    const percentage = Math.floor((totalCorrectAnswers / totalQuestions) * 100)
    return (
    <Box>
        <Stack gap="md" align='center'>
            <Image src={`/assets/images/${percentage > 50 ? "win1.png" : "failed2.png"}`} alt="Finish" w={300} />
            <Title>{percentage > 50 ? "Congratulations!!" : "Ooops!"}</Title>
            <Badge variant="light" color={percentage > 50 ? "green" : 'red'}>{percentage > 50 ? "You have completed the quiz": "Next try would be perfect"}</Badge>
            <Box p="xl" bg={percentage > 50 ? "var(--mantine-color-green-5)" : "var(--mantine-color-red-5)"} style={{
                color: 'white',
                borderRadius: '50%',
                fontWeight: 'bold',
            }}>{percentage}%</Box>
            <Button onClick={onTryAgain} variant="light" color={"green"}>Try Again</Button>
        </Stack>
    </Box>
  )
}
