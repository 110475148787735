import { useQuery } from '@tanstack/react-query'
import axios from './AxiosInstance'

export const useQuestionsQuery = (sectionUid) => {
  return useQuery(
    {
      queryKey: ['questions', sectionUid],
      queryFn: () => axios.get(`/coursex/${sectionUid}/questions/`),
      select: (response) => response.data,
      enabled: sectionUid !== undefined,
    },
  )
}
