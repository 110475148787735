import React from 'react'
import { Container, Title, Box, Text, Stack, Divider, Timeline } from '@mantine/core';
import Steps from './Steps';
import { IconGitBranch } from '@tabler/icons-react';
import { useChaptersQuery } from '../../queries/chapters';
import { useParams } from 'react-router-dom';
import FullPageLoader from '../common/FullPageLoader';

export default function CourseDetail() {
    const params = useParams()
    const {isLoading, data} = useChaptersQuery(params.slug)
    if (isLoading) return <FullPageLoader />
    if (!data || data.length === 0) return <Text align={'center'}>No chapters found</Text>
  return (
    <Container>
        <Stack gap={'md'}>
            <Box>
                <Title size={'h3'}>{data[0].course.code}</Title>
                <Text>{data[0].course.name}</Text>
                <Divider my="sm"/>
            </Box>
            
            <Timeline active={1} bulletSize={24} lineWidth={2}>
                {data.map(chapter => (
                    <Timeline.Item key={chapter.uid} bullet={<IconGitBranch size={12} />} title={chapter.name}>
                        <Text c="dimmed" size="sm">{chapter.title}</Text>
                        <Box mt="xl">
                            <Steps chapterUid={chapter.uid} />
                        </Box>
                    </Timeline.Item>
                ))}
            </Timeline>
        </Stack>
    </Container>
  )
}
