import { MantineProvider, createTheme } from '@mantine/core';
import {
  BrowserRouter,
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { Notifications } from '@mantine/notifications';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import Router from './Router';

const theme = createTheme({
  primaryColor: 'red',
});
const queryCache = new QueryCache()
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
    },
  },
})

function App() {
  return (
  <BrowserRouter>
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router />
          <Notifications />
        </QueryClientProvider>
      </MantineProvider>
    </BrowserRouter>
    
  );
}

export default App;
